@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&display=swap");

* {
  font-family: "Nunito", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  /* transition: all 0.2s linear; */
  /* color: var(--primary-color); */
}

*::selection {
  background: var(--secondary-color);
  color: #fff;
}

html {
  font-size: 50%;
  /* width: 100vw; */
  overflow-x: hidden;
  scroll-padding-top: 6.5rem;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden !important;
}

html::-webkit-scrollbar {
  /* width: 0; */
}

button {
  cursor: pointer;
}

.main-app {
  color: var(--primary-color);
  background: var(--body-background);
  padding: 1px 0 1px 0px;
  /* min-height: 100vh; */
}

.toast-container-class {
  font-size: 2rem;
  right: 0;
}

.toast-body {
  color: var(--font-color);
}

.modal-dialog {
  padding: 0;
}

.modal-dialog-centered {
  justify-content: center;
}

.modal-content {
  /* padding: 30px; */
  border-radius: 10px;
}

body.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}

body:not(.modal-open) {
  padding-right: 0px !important;
}

.green-text {
  color: var(--secondary-color);
}

.gray-text {
  color: var(--sub-text-color);
}

.react-confirm-alert {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.react-confirm-alert-body h1 {
  font-weight: bolder;
  font-size: 2.455rem;
}


.react-confirm-alert-body {
  font-weight: bold;
  font-size: 1.755rem;
  background-color: var(--second-cards-color) !important;
  color: var(--font-color) !important;
}

.react-confirm-alert-button-group button:first-child {
  background-color: var(--secondary-color);
}

.react-confirm-alert-button-group button:last-child {
  background-color: #d23b3b;
}

.back-top-container {
  height: 50px;
  text-align: center;
  width: 50px;
  background-color: var(--secondary-color) !important;
  border-color: transparent !important;
  color: white;
  margin-right: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.scroll-to-top {
  position: fixed;
  bottom: 60px;
  right: 0;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
  z-index: 10;
}

.scroll-to-top svg path {
  color: white;
}

/* media queries */

@media (max-width: 468px) {

  /* html {
    font-size: 40%;
  } */
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media only screen and (min-width: 1300px) {
  .container {
    max-width: 1610px !important;
  }
}

@media (max-width: 768px) {
  /* .modal-dialog {
    min-width: 90vw;
  } */

  .modal-content {
    padding: 15px;
  }
}

.see-all {
  font-size: 18px;
  padding: 10px 25px;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 600;
  letter-spacing: 0.5px;
  background-color: var(--secondary-color);
  color: #fff;
}

@media (max-width: 479px) {
  .see-all {
    font-size: 14px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: textfield;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.popup.modal .modal-content {
  background: transparent !important;
  border: 0 !important;
}

.popup.modal .modal-content .modal-header {
  border: 0;
  align-self: self-end;
  cursor: pointer;
}

.fssai-details {
  display: flex;
  justify-self: flex-start;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}

.fssai-details .image-container img {
  height: 25px;
  object-fit: contain;
}

.fssai-details .fssai-license-no span {
  font-size: 16px;
  font-weight: 600;
  color: var(--font-color);
}

.no-data-img {
  width: 350px;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.cursorPointer {
  cursor: pointer;
}

.offerImages {
  border-radius: var(--bd-radius-10);
  width: 100%;
  height: auto;
}

.noInternetContainer {
  height: 75vh;
  margin-bottom: 45px;
}

.noInternetContainer>svg {
  width: 500px;
  height: 500px;
  fill: var(--secondary-color);
  opacity: 1;
  animation: pulseAnimation 1.3s infinite ease-in-out;
}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.noInternetContainer>p {
  font-size: 24px;
  font-weight: 700;
  color: var(--font-color);
}

@media screen and (min-width: 0px) and (max-width: 425px) {
  .Toastify__toast {
    margin: 15px !important;
  }
}

.react-loading-skeleton {
  --highlight-color: var(--skeleton-highlight-color) !important;
  --base-color: var(--skeleton-base-color) !important;
}

.slick-slider .slick-track {
  transform: translate3d(0, 0, 0) !important;
}
.lottie-content {
  position: absolute;
  top: 0;
  width: 170px;
}

.lottie-tick {
  width: 180px;
}

.cover {
  height: 200px;
}

.cover .title {
  position: relative;
  bottom: 50%;
  text-align: center;
}

@media (max-width: 479px) {
  .cover .title {
    position: relative;
    bottom: 69%;
    text-align: center;
  }
}

.cover .title h3 {
  font-size: 2.385rem;
  font-weight: 800;
  color: var(--text-field-color);
}

.cover .title span {
  font-size: 1.985rem;
  font-weight: 600;
  color: var(--text-field-color);
}

.cover .title span.active {
  color: var(--secondary-color);
}

.cover img {
  height: 100%;
  width: 100%;
  filter: brightness(50%);
  z-index: -1;
  background-size: cover;
  object-fit: cover;
  background-position: center center;
}

@media (max-width: 479px) {
  .cover {
    height: 115px;
  }
}

.checkout-container {
  margin-top: 20px;
  padding-bottom: 30px;
  display: flex;
  gap: 20px;
}

.checkout-container .checkout-component {
  border-radius: 8px;
}

.checkout-container .checkout-component .heading,
.promo-section .heading {
  background: var(--secondary-color);
  color: var(--text-field-color) !important;
  font-size: 1.755rem;
  font-weight: 600;
  padding: 8px 15px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.promo-section .heading span {
  color: #fff;
}

.order-bill {
  background-color: var(--second-cards-color);
  border-radius: 0px 0px 8px 8px;
}

.order-bill .heading {
  width: 100%;
}

.order-bill .order-details .summary span {
  font-size: 14px;
}

.checkout-container .order-container {
  flex-grow: 1;
}

.checkout-container .checkout-util-container,
.checkout-container .order-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.checkout-container .checkout-util-container .billibg-address-wrapper,
.checkout-container .checkout-util-container .delivery-day-wrapper,
.checkout-container .checkout-util-container .delivery-time-wrapper,
.checkout-container .order-container .payment-wrapper {
  display: inline-flex;
  flex-direction: column;
  background-color: var(--second-cards-color);
}

.checkout-container .checkout-util-container .delivery-time-wrapper .estimateDeliveryDate {
  height: 75px;
  margin-left: 25px;
  font-size: 16px;
}

.checkout-container .order-container .order-instructions-wrapper .order-instruction-body {
  width: 100%;
  height: 200px;
  background-color: var(--second-cards-color);
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 0px 0px 8px 8px;
}

.checkout-container .order-container .order-instructions-wrapper .order-instruction-body .order-instructions-input {
  resize: none;
  width: 100%;
  padding: 5px;
  overflow: auto;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: var(--bd-radius-5);
}

.checkout-container .order-container .payment-wrapper .form-check-label:last-child {
  border: 0 !important;
}

.checkout-container .checkout-util-container .time-slot-container {
  display: flex;
  flex-direction: column;
  margin: 5px 15px;
  width: 140px;
  height: fit-content;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.checkout-container .checkout-util-container .time-slot-container div:first-child {
  padding: 4px 10px;
  background: var(--secondary-color);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  text-align: center;
}

.checkout-container .checkout-util-container .time-slot-container div:last-child {
  text-align: center;
  padding: 4px 10px;
  font-size: 16px;
  font-weight: 600;
  color: var(--font-color);
}

.checkout-container .order-container {
  /* min-width: 320px; */
  margin-top: 0px;
}

.checkout-container .order-container .payment-wrapper .form-check-label {
  border-bottom: 1px solid #ddd;
  color: var(--font-color);
}

.checkout-container .order-container .payment-wrapper .form-check-label input[type="radio"]:checked {
  background-color: var(--secondary-color);
  padding: 5px;
  outline-offset: 3px;
  outline: 1px solid;
}

.checkout-container .order-container .payment-wrapper .form-check-label input[type="radio"] {
  appearance: none;
  width: 10px !important;
  align-self: center !important;
  margin: 0 !important;
  height: 10px !important;
  border-radius: 50%;
  border: 0 !important;
  outline-offset: 3px;
  outline: 1px solid;
}

.checkout-container .order-container .payment-wrapper .payment-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 10px;
  margin-right: 12px;
  /*
  display: flex;
  justify-content: space-between;
   */
}

.checkout-container .order-container .payment-wrapper div img {
  width: 55px;
  height: 40px;
}

.checkout-container .order-container .payment-wrapper div:last-child {
  border-bottom: none;
}

.checkout-container .order-container .order-summary-wrapper .summary {
  padding: 20px;
  font-size: 1.555rem;
  font-weight: 700;
  color: var(--font-color);
}

.checkout-container .order-container .order-summary-wrapper .summary>div {
  padding: 5px;
  margin-top: 0px;
}

.checkout-container .order-container .order-summary-wrapper .summary .total {
  margin-top: 5px;
  border-top: 1px solid #eee;
}

.checkout-container .order-container .order-summary-wrapper .summary .total .total-amount span {
  color: var(--secondary-color);
}

.checkout-container .order-container .order-summary-wrapper .summary .button-container button {
  background: var(--secondary-color);
  border-radius: 8px;
  width: 100%;
  color: var(--text-field-color);
  padding: 8px 0;
  font-size: 14px;
  font-weight: bolder;
}

@media screen and (max-width: 64rem) {
  .checkout-container {
    flex-direction: column;
  }
}

/* react calender */
.react-calendar {
  font-family: inherit;
  font-size: 1.745rem;
  border: none;
  min-width: 100%;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  font-size: 5rem;
}

.react-calendar__navigation__label {
  font-size: 22px;
  font-weight: bold;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
  font-size: 2rem;
  color: var(--secondary-color);
  text-decoration: none;
}

.react-calendar__month-view__days {
  margin-top: 20px;
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0;
}

.react-calendar__tile.react-calendar__month-view__days__day--weekend {
  background-color: #ddd;
}

.react-calendar__tile--now:enabled {
  background: var(--secondary-color);
}

.react-calendar__tile--active {
  background-color: var(--secondary-color) !important;
  color: #fff !important;
}

.stripe-container {
  background: var(--second-cards-color);
}

.stripe-container .heading {
  color: var(--secondary-color);
  font-size: 2.555rem;
  font-weight: bolder;
}

.stripe-container button.close-stripe {
  background: none;
  font-size: 2.455rem;
}

.stripe-container .pay-stripe {
  background: var(--secondary-color);
  color: var(--text-field-color);
  width: 100%;
  font-size: 2.355rem;
  margin-top: 30px;
  border-radius: 8px;
  padding: 5px;
}

.FormRow div div input {
  border: 5px solid black;
}

.successModalDialog {
  width: 500px !important;
  height: 500px !important;
}

.successModalDialog>.modal-content {
  height: 100%;
}

.success_modal .success_body {
  font-size: 24px;
  border: 0;
  text-align: center;
  color: var(--font-color);
}

.success_modal .success_header {
  border: 0;
}

.success_modal .success_footer {
  border: 0;
  justify-content: center;
}

.success_modal .checkout_btn {
  width: 50%;
  height: 25%;
  font-size: 18px;
  z-index: 9;
  background-color: var(--secondary-color);
  color: var(--bs-white);
  border: 0;
  font-weight: 700;
  margin-top: 50px;
}

@media screen and (min-width: 0px) and (max-width: 510px) {
  .successModalDialog {
    width: 100% !important;
  }
}

.order-container .promo-section {
  margin-bottom: 20px;
}

.order-container .promo-section .promo-wrapper {
  padding: 20px;
  font-size: 1.555rem;
  background-color: var(--second-cards-color);
  color: var(--font-color);
  font-weight: 700;
}

.order-container .promo-section .promo-wrapper .promo-container .promo-button {
  font-size: 16px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.order-container .promo-section .promo-wrapper .promo-container .promo-button {
  font-size: 16px;
}

.order-container .promo-section .promo-wrapper .promo-container .promo-button button {
  background-color: var(--second-cards-color);
  padding: 8px;
  border-radius: 5px;
  border: 2px solid #ddd;
  font-size: 14px;
  color: #000;
}

.order-container .promo-section .promo-wrapper .promo-container .promo-code {
  background-color: var(--secondary-color-light);
  border: 1px dashed var(--secondary-color);
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-container .promo-section .promo-wrapper .promo-container .promo-code .promo-name {
  font-weight: 500;
}

.order-container .promo-section .promo-wrapper .promo-container .promo-code .promo-remove {
  color: red;
  font-size: 14px;
  font-weight: 600;
  align-self: center;
  cursor: pointer;
}

@media screen and (min-width: 0px) and (max-width: 320px) {

  .checkout-container .checkout-util-container,
  .checkout-container .order-container {
    width: 300px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .checkout-container.container {
    align-items: center;
  }

  .checkout-container .order-container {
    width: 700px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .checkout-container .order-container {
    padding-right: 20px;
  }
}

.checkoutCalendar .react-calendar__navigation__label__labelText,
.checkoutCalendar .react-calendar__navigation__arrow,
.checkoutCalendar .react-calendar__tile {
  color: black;
}

#checkout .calendarContainer {
  background-color: var(--second-cards-color);
  border-bottom-left-radius: var(--bd-radius-8);
  border-bottom-right-radius: var(--bd-radius-8);
}

.order-success-desc {
  color: var(--font-color)
}

.checkout-warning-msg {
  display: flex;
  align-items: center;
  color: var(--font-color);
}

.checkout-location-btn {
  cursor: pointer;
  padding: 5px;
  background-color: transparent;
  border-bottom: 1px solid var(--font-color);
}
.product-grid {

    background-color: var(--bs-body-bg);

    /* border-radius: 4px; */
    padding: 12px;
    border: 1px solid var(--border-color);
    /* position: relative; */
    overflow: hidden;
}


.product-grid .product-image {
    overflow: hidden;
    position: relative;
}

.product-grid .product-image a.image {
    display: block;
}

.product-grid .product-image img {
    width: 100%;
    height: 100%;
    /* width: auto; */
    margin: auto;
    /* height: auto; */
    /* transition: all 0.3s ease-out 0s; */
    object-fit: cover;
    aspect-ratio: 261/241;
    border-radius: 5px;
}

.product-grid:hover .product-image img {
    opacity: 0.8;
}

/* .product-grid .product-image:hover img {
    transform: scale(1.15);
} */

.product-grid .product-discount-label {
    color: #fff;
    text-align: center;
    background: #DB3D26;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 16px;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    position: absolute;
    top: 0px;
    left: 0px;
    /* z-index: 10; */
}

.product-grid .product-vegen-type {
    background: transparent;
    padding: 4px 8px 4px 8px;
    position: absolute;
    top: 80%;
    right: 0px;
    /* z-index: 10; */
}

.product-grid .product-vegen-type img {
    object-fit: contain !important;
}

.product-grid .product-links {
    padding: 0;
    margin: 0;
    list-style: none;
    opacity: 0;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    transition: all .2s ease-in-out 0.2s;
    text-align: center;
}

.product-grid:hover .product-links {
    opacity: 1;

}

.product-grid .product-links li {
    margin: 0 0 6px;
    display: block;
    transform: translateX(30px);
    transition: all 0.5s ease-out 0s;
}

.product-grid .product-links li:nth-child(2) {
    transform: translateX(50px);
}

.product-grid .product-links li:nth-child(3) {
    transform: translateX(70px);
}

.product-grid .product-links li:last-child {
    transform: translateX(90px);
}

.product-grid .product-links li:last-child {
    margin: 0;
}

.product-grid:hover .product-links li {
    transform: translateX(0);
}


.product-grid .product-links li a {
    color: #2a3640;
    background: #fff;
    font-size: 16px;
    line-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: block;
    position: relative;
    /* box-shadow: 0 0 30px 0 rgba(0, 0, 0, .06); */
    transition: all 0.5s ease-out 0s;
    background-color: var(--second-cards-color);
    color: var(--font-color);
    border: 1px solid var(--border-color);
    align-items: center;
    display: flex;
    justify-content: center;

}




.product-grid .product-links li a:hover {
    color: #fff;
    background: var(--secondary-color);
}

.product-grid .product-links li a:before,
.product-grid .product-links li a:after {
    content: attr(data-tip);
    color: #fff;
    background: var(--secondary-color);
    font-size: 12px;
    line-height: 20px;
    padding: 5px 10px;
    border-radius: 3px;
    white-space: nowrap;
    display: none;
    transform: translateY(-50%);
    position: absolute;
    right: 45px;
    top: 50%;
}

.product-grid .product-links li a:after {
    content: '';
    height: 15px;
    width: 15px;
    padding: 0;
    border-radius: 0;
    transform: translateY(-50%) rotate(45deg);
    right: 43px;
    z-index: -1;
}

.product-grid .product-links li a:hover:before,
.product-grid .product-links li a:hover:after {
    display: block;
}

.product-grid .product-content {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.product-grid .title {
    margin-top: 12px;
    color: #2a3640;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    width: 100%;
    max-height: 2.4em;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-grid .title a {
    color: inherit;
    text-decoration: none;
}

.product-grid .title a {
    color: var(--font-color) !important;
    transition: all 0.3s ease 0s;
}

.product-grid .title a:hover {

    color: var(--secondary-color)
}

.product-grid .rating {
    color: var(--font-color);
    font-size: 12px;

    margin: 0 0 4px;
    align-items: center;
    display: flex;

}

.product-grid .rating p {
    font-size: 14px;
    color: gray;
    margin: 2px 8px;
}

.product-grid .rating .disable {
    color: #a3a3a3;
}

.product-grid .price {
    color: var(--font-color);
    font-size: 16px;
    font-weight: 700;
    /* margin-top: 10px; */
    /* padding: 12px 12px 0px 12px; */

}

.product-grid .price span {
    color: #868c93;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-decoration: line-through;
    margin: 4px;
    /* margin: 5px; */
}

.vertical-product-btn {
    width: 100%;
    display: flex;
    /* margin: 12px; */
    height: 38px;
    gap: 12px;
}

.product-qty-btn {
    padding: 5px;
    font-size: 14px;
    margin: 5px 0px;
    display: flex;
    width: 100%;
    border-radius: 4px;
    background-color: var(--buttons-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.vertical-product-btn .dropdown-toggle {
    padding: 12px;
    font-size: 16px;
    margin: 5px 0px;
    display: flex;
    width: 100%;
    border-radius: 4px;
    background-color: #F2F5F7;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--font-color);
    border: none;
}

.product-cart-btn {
    padding: 12px;
    font-size: 16px;
    margin: 5px 0px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #55AE7B12;
    gap: 4px;
    color: var(--secondary-color);
}

.product-cart-btn:hover {
    background-color: var(--secondary-color);
    color: #fff;
}

/* @media screen and (max-width: 990px) {
    .product-grid {
        margin-bottom: 30px;
    }
} */

.filledStar {
    color: #ffcc00;
    font-size: 14px;
}

.emptyStar {
    color: #dcdcdc;
    font-size: 14px;
}


.cart-count-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 4px;
    /* height: 100%; */
    /* padding: 8px; */
    font-size: 16px;
    height: 84%;
    margin: 5px 0px;
    border: 1px solid var(--border-color);
    align-items: center;
}



.cart-count-btn input {
    text-align: center;
    background: #fff;
    width: 100%;
    background: transparent;
}

.cart-count-btn button {
    /* height: 119%; */
    /* padding: 7px 10px; */
    border-radius: 2px;
    background-color: var(--secondary-color);
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    height: 30px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.vertical-variant-out-of-stock {
    font-size: 16px;
    color: #DB3D26;
    font-weight: 900;
    display: flex;
    /* justify-content: end; */
    /* width: 100%; */
    height: 38px;
    align-items: center;
}

@media (max-width:400px) {
    .vertical-variant-out-of-stock {
        font-size: 16px;
        color: #DB3D26;
        font-weight: 900;
        display: flex;
        /* justify-content: end; */
        /* width: 100%; */
        height: 75px;
        align-items: center;

    }

    .vertical-product-btn {
        width: 100%;
        display: flex;
        /* margin: 12px; */
        height: 75px;
        gap: 0px;
        flex-direction: column;
    }
}
.cart-sidebar-container .cart-sidebar-product .products-container .cart-card .left-wrapper .product-details .add-to-cart.active,
.view-cart-container .viewcart-product-wrapper .products-table tbody .quantity .add-to-cart.active {
  background: var(--secondary-color);
  border-radius: 5px;
  color: var(--text-field-color) !important;
  font-size: 14px;
  width: fit-content;

  padding: 4px 12px;
}

.view-cart-container .viewcart-product-wrapper .products-table .products-image-container .variant-section {
  background-color: var(--body-background) !important;
  border-radius: 5px;
  color: var(--font-color) !important;
  font-size: 14px !important;
  opacity: 0.7;
  padding: 4px 10px !important;
  align-items: center;
  border: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.view-cart-container .viewcart-product-wrapper .products-table tbody .price span {
  color: var(--secondary-color);
}

/* .wishlist{
    min-height: 75vh;
} */

#wishlist .cover {
  height: 200px;
}

#wishlist .cover img {
  height: 100%;
  width: 100%;
  filter: brightness(50%);
  z-index: -1;
  background-size: cover;
  background-position: center center;
}

#wishlist .cover .title {
  position: relative;
  bottom: 50%;
  text-align: center;
}

#wishlist .cover .title h3 {
  font-size: 2.385rem;
  font-weight: 800;
  color: var(--text-field-color);
}

#wishlist .cover .title span {
  font-size: 1.985rem;
  font-weight: 600;
  color: var(--text-field-color);
}

#wishlist .cover .title span.active {
  color: var(--secondary-color);
}

#Wishlist .add-to-cart.disabled {
  color: var(--text-field-color) !important;
}

@media (max-width: 768px) {
  #wishlist .products-image-container.first-column .out_of_stockText {
    color: red;
    font-size: 7px;
    font-weight: normal;
    text-align: center;
    padding: 4px 8px;
    background: var(--second-cards-color);
    border-radius: 5px;
  }
}